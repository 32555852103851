import {
  PincodeStateObj,
  CityStateObj,
  DropDownData,
} from '@/interfaces/app_config';

export const EMAIL_REGEX: RegExp =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX: RegExp = /^[6-9]\d{9}$/;
export const REFID_REGEX: RegExp = /^[A-Z]{2,12}[0-9]{8,30}[A-Z]*$/;

export const sectionsDisplayFields: any = {
  profile: [
    {
      id: 'fullName',
      name: 'Name',
      width: 'full',
      editable: false,
    },
    {
      id: 'email',
      name: 'Email',
      width: 'full',
      editable: false,
    },
    {
      id: 'phone',
      name: 'Phone',
      width: 'half',
      editable: false,
    },
    {
      id: 'partnerCode',
      name: 'Partner',
      width: 'half',
      editable: false,
    },
  ],
  personalDetails: [
    {
      id: 'dob',
      name: 'Date of birth',
      width: 'half',
      editable: false,
    },
    {
      id: 'gender',
      name: 'Gender',
      width: 'half',
      editable: false,
    },
    {
      id: 'maritalStatus',
      name: 'Marital Status',
      width: 'half',
      editable: false,
    },
  ],
  residenceDetails: [
    {
      id: 'currentAddress.addressLine1',
      name: 'Address Line 1',
      width: 'full',
      editable: true,
    },
    {
      id: 'currentAddress.addressLine2',
      name: 'Address Line 2',
      width: 'full',
      editable: true,
    },
    {
      id: 'currentAddress.citySubdivision1',
      name: 'City Subdivision 1',
      width: 'half',
      editable: true,
    },
    {
      id: 'currentAddress.citySubdivision2',
      name: 'City Subdivision 2',
      width: 'half',
      editable: true,
    },
    {
      id: 'currentAddress.landmark',
      name: 'Landmark',
      width: 'half',
      editable: true,
    },
    {
      id: 'currentAddress.city',
      name: 'City',
      width: 'half',
      editable: true,
    },
    {
      id: 'currentAddress.state',
      name: 'State',
      width: 'half',
      editable: true,
    },
    {
      id: 'currentAddress.pincode',
      name: 'Pin Code',
      width: 'half',
      editable: true,
    },
    {
      id: 'durationInCurrentAddress',
      name: 'Duration In Current Address (in months)',
      width: 'half',
      editable: false,
    },
    {
      id: 'durationInCurrentCity',
      name: 'Duration In Current City (in months)',
      width: 'half',
      editable: false,
    },
  ],
  workDetails: [
    {
      id: 'workAddress.addressLine1',
      name: 'Address Line 1',
      width: 'full',
      editable: true,
    },
    {
      id: 'workAddress.addressLine2',
      name: 'Address Line 2',
      width: 'full',
      editable: true,
    },
    {
      id: 'workAddress.citySubdivision1',
      name: 'City Subdivision 1',
      width: 'half',
      editable: true,
    },
    {
      id: 'workAddress.citySubdivision2',
      name: 'City Subdivision 2',
      width: 'half',
      editable: true,
    },
    {
      id: 'workAddress.landmark',
      name: 'Landmark',
      width: 'half',
      editable: true,
    },
    {
      id: 'workAddress.city',
      name: 'City',
      width: 'half',
      editable: true,
    },
    {
      id: 'workAddress.state',
      name: 'State',
      width: 'half',
      editable: true,
    },
    {
      id: 'workAddress.pincode',
      name: 'Pin Code',
      width: 'half',
      editable: true,
    },
    {
      id: 'jobType',
      name: 'Job Type',
      width: 'half',
      editable: false,
    },
    {
      id: 'jobTitle',
      name: 'Job Title',
      width: 'half',
      editable: false,
    },
    {
      id: 'companyName',
      name: 'Company Name',
      width: 'half',
      editable: false,
    },
    {
      id: 'experienceInCurrentCompany',
      name: 'Experience In Current Company (in months)',
      width: 'half',
      editable: false,
    },
    {
      id: 'totalExperience',
      name: 'Total Experience (in months)',
      width: 'half',
      editable: false,
    },
    {
      id: 'salary',
      name: 'Salary',
      width: 'half',
      editable: false,
    },
  ],
  customerReferences: [
    {
      id: 'customerReference.firstName',
      name: 'First Name',
      width: 'half',
      editable: true,
    },
    {
      id: 'customerReference.middleName',
      name: 'Middle Name',
      width: 'half',
      editable: true,
    },
    {
      id: 'customerReference.lastName',
      name: 'Last Name',
      width: 'half',
      editable: true,
    },
    {
      id: 'customerReference.phone',
      name: 'Phone',
      width: 'half',
      editable: true,
    },
    {
      id: 'customerReference.relation',
      name: 'Relation',
      width: 'half',
      editable: true,
    },
  ],
};

export const SECTIONS: string[] = [
  'profile',
  'personalDetails',
  'residenceDetails',
  'workDetails',
  'customerReferenceDetails',
  'groupedDocumentDetails',
];

export const TASK_STATUS_OPTIONS: Record<string, any> = {
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    desc: 'In Progress',
  },
  COMPLETED: {
    value: 'COMPLETED',
    desc: 'Completed',
  },
  BLOCKED: {
    value: 'BLOCKED',
    desc: 'Blocked',
  },
};

export const sectionConfig: Record<string, any> = {
  profile: {
    icon: 'user',
    component: 'sections',
    title: 'Profile',
    section: 'profile',
    props: {
      sectionId: 'profile',
      sectionTitle: 'Profile',
      sectionDetails: 'customerDetails.profile',
      isEditable: false,
      expandDetails: true,
    },
  },
  personalDetails: {
    icon: 'idcard',
    component: 'sections',
    title: 'Personal Details',
    section: 'personalDetails',
    props: {
      sectionId: 'personalDetails',
      sectionTitle: 'Personal Details',
      sectionDetails: 'customerDetails.personalDetails',
      isEditable: false,
      expandDetails: true,
    },
  },
  residenceDetails: {
    icon: 'home',
    component: 'sections',
    title: 'Residence Details',
    section: 'residenceDetails',
    props: {
      sectionId: 'residenceDetails',
      sectionTitle: 'Residence Details',
      sectionDetails: 'customerDetails.residenceDetails',
      isEditable: true,
      expandDetails: true,
    },
  },
  workDetails: {
    icon: 'desktop',
    component: 'sections',
    title: 'Work Details',
    section: 'workDetails',
    props: {
      sectionId: 'workDetails',
      sectionTitle: 'Work Details',
      sectionDetails: 'customerDetails.workDetails',
      isEditable: true,
      expandDetails: true,
    },
  },
  references: {
    icon: 'team',
    component: 'sections',
    title: 'Customer References',
    section: 'customerReferences',
    props: {
      sectionId: 'customerReferences',
      sectionTitle: 'Customer References',
      sectionDetails: 'customerDetails.customerReferences',
      isEditable: true,
      expandDetails: true,
    },
  },
  documents: {
    icon: 'picture',
    component: 'kyc-docs',
    title: 'Uploaded Documents',
    section: 'groupedDocumentSection',
  },
  analysis: {
    icon: 'pie-chart',
    component: '',
    title: 'Analysis',
    section: 'empty',
  },
  creditReports: {
    icon: 'profile',
    component: '',
    title: 'Credit Reports',
    section: 'empty',
  },
  defaultSection: {
    icon: 'file-unknown',
    component: '',
    title: 'Unknown',
    section: '',
  },
};

export const kycDocRejectionReasons: string[] = [
  'Verified',
  'Photo of an existing photograph',
  'Dark selfie',
  'More people in selfie',
  'Complete face not present',
  'Blurry Image',
  'Corners are cut',
  'Black and white image/xerox',
  'Not a photo of original Document',
  'Name mismatch',
  'DOB mismatch',
  'Incorrect document uploaded',
  'Document Expired',
  'Document older than 2 months',
  "Can't be used as both Address and Id proof",
  'Photo on the ID is not clear',
  'Inappropriate Selfie',
  "Can't open document - password protected",
  'Need KYC with compelete DOB (DD/MM/YYYY)',
  'Not an original image',
  'Selfie Mismatch with the person in KYC',
  'Need selfie without Sunglasses',
  'Eyes should be visible in the Selfie',
  'Current City Address Proof Required',
  'Incomplete Work Address',
  'Incomplete Home Address',
  'Old nach form not matching latest beneficiary details',
];

export const selfieRepickupReasons = {
  'Selfie Not Available': 'Selfie Not Available',
  'Multiple People in The Selfie. Need Selfie With Only Customer':
    'Multiple People in The Selfie. Need Selfie With Only Customer',
  'Incorrect Selfie': 'Incorrect Selfie',
  'Blurry Selfie': 'Blurry Selfie',
  'Need Appropriate Selfie': 'Need Appropriate Selfie',
};

export const sectionMapping: Record<string, string> = {
  profile: 'profile',
  personalDetails: 'personalDetails',
  residenceDetails: 'residenceDetails',
  workDetails: 'workDetails',
  documents: 'groupedDocumentDetails',
  references: 'customerReferenceDetails',
  milestones: 'milestoneDetails',
  transactions: 'transactionDetails',
  financialReport: 'creditReportDetails',
  incomeReport: 'incomeReportDetails',
};

export const INPUT_DATE_REGEX: RegExp = /([0-9]{2})-([0-9]{2})-([0-9]{4})/;

export const OUTPUT_DATE_REGEX: RegExp = /([0-9]{4})-([0-9]{2})-([0-9]{2})/;

export const states: PincodeStateObj[] = [
  {
    name: 'Andaman & Nicobar Islands',
    min: 74,
    max: 74,
  },
  {
    name: 'Andhra Pradesh',
    min: 50,
    max: 53,
  },
  {
    name: 'Arunachal Pradesh',
    min: 79,
    max: 79,
  },
  {
    name: 'Assam',
    min: 78,
    max: 78,
  },
  {
    name: 'Bihar',
    min: 80,
    max: 85,
  },
  {
    name: 'Chandigarh',
    min: 16,
    max: 16,
  },
  {
    name: 'Chhattisgarh',
    min: 49,
    max: 49,
  },
  {
    name: 'Dadra & Nagar Haveli',
    min: 39,
    max: 39,
  },
  {
    name: 'Daman & Diu',
    min: 36,
    max: 39,
  },
  {
    name: 'Delhi',
    min: 11,
    max: 11,
  },
  {
    name: 'Goa',
    min: 40,
    max: 40,
  },
  {
    name: 'Gujarat',
    min: 36,
    max: 39,
  },
  {
    name: 'Haryana',
    min: 12,
    max: 13,
  },
  {
    name: 'Himachal Pradesh',
    min: 17,
    max: 17,
  },
  {
    name: 'Jammu  & Kashmir',
    min: 18,
    max: 19,
  },
  {
    name: 'Jharkhand',
    min: 80,
    max: 85,
  },
  {
    name: 'Karnataka',
    min: 56,
    max: 59,
  },
  {
    name: 'Kerala',
    min: 67,
    max: 69,
  },
  {
    name: 'Lakshadweep',
    min: 68,
    max: 68,
  },
  {
    name: 'Madhya Pradesh',
    min: 45,
    max: 48,
  },
  {
    name: 'Maharashtra',
    min: 40,
    max: 44,
  },
  {
    name: 'Manipur',
    min: 79,
    max: 79,
  },
  {
    name: 'Meghalaya',
    min: 78,
    max: 79,
  },
  {
    name: 'Mizoram',
    min: 79,
    max: 79,
  },
  {
    name: 'Nagaland',
    min: 78,
    max: 79,
  },
  {
    name: 'Orissa',
    min: 75,
    max: 77,
  },
  {
    name: 'Pondicherry',
    min: 60,
    max: 60,
  },
  {
    name: 'Punjab',
    min: 14,
    max: 15,
  },
  {
    name: 'Rajasthan',
    min: 30,
    max: 34,
  },
  {
    name: 'Sikkim',
    min: 73,
    max: 73,
  },
  {
    name: 'Tamil Nadu',
    min: 60,
    max: 64,
  },
  {
    name: 'Telangana',
    min: 50,
    max: 53,
  },
  {
    name: 'Tripura',
    min: 79,
    max: 79,
  },
  {
    name: 'Uttaranchal',
    min: 24,
    max: 26,
  },
  {
    name: 'Uttarakhand',
    min: 24,
    max: 26,
  },
  {
    name: 'Uttar Pradesh',
    min: 20,
    max: 28,
  },
  {
    name: 'West Bengal',
    min: 70,
    max: 74,
  },
];

export const cities: CityStateObj[] = [
  {
    city: 'Ahmedabad',
    state: 'Gujarat',
  },
  {
    city: 'Anand',
    state: 'Gujarat',
  },
  {
    city: 'Ambala',
    state: 'Haryana',
  },
  {
    city: 'Bangalore',
    state: 'Karnataka',
  },
  {
    city: 'Bharuch',
    state: 'Gujarat',
  },
  {
    city: 'Bhopal',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Chandigarh',
    state: 'Chandigarh',
  },
  {
    city: 'Chennai',
    state: 'Tamil Nadu',
  },
  {
    city: 'Cochin',
    state: 'Kerala',
  },
  {
    city: 'Coimbatore',
    state: 'Tamil Nadu',
  },
  {
    city: 'Dehradun',
    state: 'Uttarakhand',
  },
  {
    city: 'Delhi',
    state: 'Delhi',
  },
  {
    city: 'Erode',
    state: 'Tamil Nadu',
  },
  {
    city: 'Faridabad',
    state: 'Haryana',
  },
  {
    city: 'Gandhinagar',
    state: 'Gujarat',
  },
  {
    city: 'Ghaziabad',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Greater Noida',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Guntur',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Gurgaon',
    state: 'Haryana',
  },
  {
    city: 'Guwahati',
    state: 'Assam',
  },
  {
    city: 'Haridwar',
    state: 'Uttarakhand',
  },
  {
    city: 'Hooghly',
    state: 'West Bengal',
  },
  {
    city: 'Howrah',
    state: 'West Bengal',
  },
  {
    city: 'Hyderabad',
    state: 'Telangana',
  },
  {
    city: 'Indore',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Jaipur',
    state: 'Rajasthan',
  },
  {
    city: 'Jodhpur',
    state: 'Rajasthan',
  },
  {
    city: 'K.V.Rangareddy',
    state: 'Telangana',
  },
  {
    city: 'Kochi',
    state: 'Kerala',
  },
  {
    city: 'Kolhapur',
    state: 'Maharashtra',
  },
  {
    city: 'Kolkata',
    state: 'West Bengal',
  },
  {
    city: 'Lucknow',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Ludhiana',
    state: 'Punjab',
  },
  {
    city: 'Mangalore',
    state: 'Karnataka',
  },
  {
    city: 'Mohali',
    state: 'Punjab',
  },
  {
    city: 'Mumbai',
    state: 'Maharashtra',
  },
  {
    city: 'Nagpur',
    state: 'Maharashtra',
  },
  {
    city: 'Nashik',
    state: 'Maharashtra',
  },
  {
    city: 'Navi Mumbai',
    state: 'Maharashtra',
  },
  {
    city: 'New Delhi',
    state: 'Delhi',
  },
  {
    city: 'Noida',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Panchkula',
    state: 'Haryana',
  },
  {
    city: 'Pune',
    state: 'Maharashtra',
  },
  {
    city: 'Raipur',
    state: 'Chhattisgarh',
  },
  {
    city: 'Rajkot',
    state: 'Gujarat',
  },
  {
    city: 'Salem',
    state: 'Tamil Nadu',
  },
  {
    city: 'Secunderabad',
    state: 'Telangana',
  },
  {
    city: 'Surat',
    state: 'Gujarat',
  },
  {
    city: 'Tiruchirappalli',
    state: 'Tamil Nadu',
  },
  {
    city: 'Tirupati',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Thane',
    state: 'Maharashtra',
  },
  {
    city: 'Udaipur',
    state: 'Rajasthan',
  },
  {
    city: 'Vadodara',
    state: 'Gujarat',
  },
  {
    city: 'Vijayawada',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Vishakapatnam',
    state: 'Andhra Pradesh',
  },
];

export const invalidNameParts: string[] = [
  'mr',
  'mrs',
  'miss',
  'ji',
  'shri',
  'shree',
  'jee',
  'col',
  'sri',
  'dr',
  'prof',
  'sir',
];

export const PRODUCT_VARIANT_TYPES: DropDownData[] = [
  {
    key: 'BOLT',
    value: 'Bolt',
  },
  {
    key: 'DEFAULT',
    value: 'Default',
  },
];

export const BOOLEAN_OPTIONS: DropDownData[] = [
  {
    key: 'YES',
    value: 'Yes',
  },
  {
    key: 'NO',
    value: 'No',
  },
];

export const PRODUCT_SUB_TYPE_VARIANTS: DropDownData[] = [
  {
    key: 'LCL',
    value: 'LCL',
  },
  {
    key: 'MCL',
    value: 'MCL',
  },
  {
    key: 'NCL',
    value: 'NCL',
  },
];

export const TAG_VARIANTS: DropDownData[] = [
  {
    key: 'experimental',
    value: 'experimental',
  },
  {
    key: 'non-experimental',
    value: 'non - experimental',
  },
];

export const APP_SOURCE_TYPE_VARIANTS: DropDownData[] = [
  {
    key: 'DSA',
    value: 'DSA',
  },
  {
    key: 'NON-DSA',
    value: 'NON - DSA',
  },
];

export const GENERATION_VARIANTS: DropDownData[] = [
  {
    key: 'NONE',
    value: 'None',
  },
  {
    key: 'NEW',
    value: 'New',
  },
  {
    key: 'GEN-1',
    value: 'Gen - 1',
  },
  {
    key: 'GEN-2',
    value: 'Gen - 2',
  },
  {
    key: 'GEN-3',
    value: 'Gen - 3',
  },
  {
    key: 'CHURN-1',
    value: 'Churn process - 1',
  },
  {
    key: 'CHURN-2',
    value: 'Churn process - 2',
  },
];

export const FINANCE_REPORT_REQUIREMENT_VARIANTS: DropDownData[] = [
  {
    key: 'MANDATORY',
    value: 'Mandatory',
  },
  {
    key: 'OPTIONAL',
    value: 'Optional',
  },
  {
    key: 'NOT_REQUIRED',
    value: 'Not Required',
  },
];

export const TASK_TYPES: DropDownData[] = [
  {
    key: 'Tele Verification',
    value: 'Tele Verification',
  },
  {
    key: 'Tele Selling',
    value: 'Tele Selling',
  },
  {
    key: 'Soft Approval',
    value: 'Soft Approval',
  },
  {
    key: 'KYC Review',
    value: 'KYC Review',
  },
  {
    key: 'Curing',
    value: 'Curing',
  },
  {
    key: 'Fulfillment',
    value: 'Fulfillment',
  },
  {
    key: 'Tele Nudge',
    value: 'Tele Nudge',
  },
];

export const PARTNERS: DropDownData[] = [
  {
    key: 'RBL',
    value: 'Rbl',
  },
  {
    key: 'INCRED',
    value: 'Incred',
  },
  {
    key: 'MUSKMELON',
    value: 'Muskmelon',
  },
  {
    key: 'TAPSTART',
    value: 'Tapstart',
  },
  {
    key: 'FULLERTON',
    value: 'Fullerton',
  },
  {
    key: 'HDBFS',
    value: 'HDBFS',
  },
  {
    key: 'CREDITSAISON',
    value: 'Credit Saison',
  },
  {
    key: 'LENDBOX',
    value: 'Lend Box',
  },
  {
    key: 'CHOLAMANDALAM',
    value: 'Cholamandalam',
  },
  {
    key: 'KOMAC',
    value: 'Komac',
  },
  {
    key: 'ASPIRE',
    value: 'Aspire',
  },
  {
    key: 'PAYU',
    value: 'Payu',
  },
];

export const STATUS_TYPES: DropDownData[] = [
  {
    key: 'IN_PROGRESS',
    value: 'In Progress',
  },
  {
    key: 'COMPLETED',
    value: 'Completed',
  },
  {
    key: 'BLOCKED',
    value: 'Blocked',
  },
];

export const EDUCATIONAL_QUAL_TYPES: DropDownData[] = [
  {
    key: 'UNDERGRADUATE',
    value: 'Undergraduate',
  },
  {
    key: 'GRADUATE',
    value: 'Graduate',
  },
  {
    key: 'POSTGRADUATE',
    value: 'Postgraduate',
  },
];

export const LOAN_REQ_REASON_TYPES: DropDownData[] = [
  {
    key: 'MARRIAGE',
    value: 'Marriage',
  },
  {
    key: 'HOUSE_RENOVATION',
    value: 'House Renovation',
  },
  {
    key: 'PAY_EXISTING_LOAN',
    value: 'Pay Existing Loan',
  },
  {
    key: 'EDUCATION',
    value: 'Education',
  },
  {
    key: 'HOUSE_DEPOSIT',
    value: 'House Deposit',
  },
  {
    key: 'MEDICAL_EMERGENCY',
    value: 'Medical Emergency',
  },
  {
    key: 'BUSINESS',
    value: 'Business',
  },
  {
    key: 'PERSONAL',
    value: 'Personal',
  },
  {
    key: 'OTHER',
    value: 'Other',
  },
];

export const MARITAL_STATUS_TYPES: DropDownData[] = [
  {
    key: 'MARRIED',
    value: 'MARRIED',
  },
  {
    key: 'SINGLE',
    value: 'SINGLE',
  },
];

export const JOB_TYPES: DropDownData[] = [
  {
    key: 'SALARIED',
    value: 'Salaried',
  },
  {
    key: 'SELF_EMPLOYED_BUSINESS',
    value: 'Self Employed Business',
  },
  {
    key: 'SELF_EMPLOYED_PROFESSIONAL',
    value: 'Self Employed professional',
  },
  {
    key: 'STUDENT',
    value: 'Student',
  },
  {
    key: 'HOMEMAKER',
    value: 'Homemaker',
  },
  {
    key: 'RETIRED',
    value: 'Retired',
  },
  {
    key: 'SELF_EMPLOYED',
    value: 'Self Employed',
  },
];

export const EMPLOYMENT_TYPES: DropDownData[] = [
  {
    key: 'FULL_TIME',
    value: 'Full Time',
  },
  {
    key: 'PART_TIME',
    value: 'Part Time',
  },
  {
    key: 'CONTRACTUAL',
    value: 'Contractual',
  },
];

export const MODES_OF_SALARY: DropDownData[] = [
  {
    key: 'CASH',
    value: 'Cash',
  },
  {
    key: 'CHEQUE',
    value: 'Cheque',
  },
  {
    key: 'NETBANKING',
    value: 'Netbanking',
  },
];

export const RESIDENCE_TYPES: DropDownData[] = [
  {
    key: 'UNKNOWN',
    value: 'UNKNOWN',
  },
  {
    key: 'RENTED',
    value: 'RENTED',
  },
  {
    key: 'OWNED',
    value: 'OWNED',
  },
  {
    key: 'OWNED_BY_SELF_SPOUSE',
    value: 'OWNED_BY_SELF_SPOUSE',
  },
  {
    key: 'OWNED_BY_PARENTS_SIBLING',
    value: 'OWNED_BY_PARENTS_SIBLING',
  },
  {
    key: 'RENTED_WITH_FAMILY',
    value: 'RENTED_WITH_FAMILY',
  },
  {
    key: 'RENTED_WITH_FRIENDS',
    value: 'RENTED_WITH_FRIENDS',
  },
  {
    key: 'RENTED_ALONE',
    value: 'RENTED_ALONE',
  },
  {
    key: 'GUEST_HOUSE',
    value: 'GUEST_HOUSE',
  },
  {
    key: 'HOSTEL',
    value: 'HOSTEL',
  },
  {
    key: 'COMPANY_LEASE',
    value: 'COMPANY_LEASE',
  },
  {
    key: 'OWNED_BY_RELATIVE',
    value: 'OWNED_BY_RELATIVE',
  },
];

export const UPDATE_DOC_STATUS: DropDownData[] = [
  {
    key: 'PENDING',
    value: 'PENDING',
  },
  {
    key: 'COLLECTED',
    value: 'COLLECTED',
  },
  {
    key: 'VERIFIED',
    value: 'VERIFIED',
  },
  {
    key: 'CANCELLED',
    value: 'CANCELLED',
  },
];

export const GENDER_TYPES: DropDownData[] = [
  {
    key: 'MALE',
    value: 'MALE',
  },
  {
    key: 'FEMALE',
    value: 'FEMALE',
  },
  {
    key: 'OTHER',
    value: 'OTHER',
  },
];

export const COMPANY_TYPES: DropDownData[] = [
  {
    key: 'MULTINATIONAL',
    value: 'Multinational',
  },
  {
    key: 'CENTRAL_STATE_GOVT',
    value: 'Central/State Govt',
  },
  {
    key: 'PRIVATE_LIMITED',
    value: 'Private Limited',
  },
  {
    key: 'PROPRIETORSHIP',
    value: 'Proprietorship',
  },
  {
    key: 'INDIVIDUAL',
    value: 'Individual',
  },
  {
    key: 'HOSPITAL',
    value: 'Hospital',
  },
  {
    key: 'COLLEGE',
    value: 'College',
  },
  {
    key: 'LLP',
    value: 'LLP',
  },
  {
    key: 'PARTNERSHIP_FIRM',
    value: 'Partnership Firm',
  },
  {
    key: 'HINDU_UNDIVIDED_FAMILY',
    value: 'Hindu Undivided Family',
  },
  {
    key: 'OTHER',
    value: 'Other including Society/Trust/AOP',
  },
];

export const CITIES: DropDownData[] = cities.map((ele) => ({
  key: ele.city,
  value: ele.city,
}));

export const TASK_CODE_MAPPING: Record<string, string> = {
  SOFT_APPROVAL: 'SOFT_APPROVAL',
  TELE_VERIFICATION: 'TELE_VERIFICATION',
  FULFILLMENT: 'FULFILLMENT',
  PICKUP_APPOINTMENT: 'PICKUP_APPOINTMENT',
  PARTNER_OPS: 'PARTNER_OPS',
  TELE_SELLING: 'TELE_SELLING',
  FULFILLMENT_REVIEW: 'FULFILLMENT_REVIEW',
  TELE_NUDGE: 'TELE_NUDGE',
};

export const TASK_CODE_TITLE_MAPPING: Record<string, string> = {
  SOFT_APPROVAL: 'Soft Approval',
  TELE_VERIFICATION: 'Tele Verification',
  FULFILLMENT: 'Fulfillment',
  PICKUP_APPOINTMENT: 'Pickup Appointment',
  PARTNER_OPS: 'Partner Ops',
  TELE_SELLING: 'Tele Selling',
  FULFILLMENT_REVIEW: 'Fulfillment Review',
  TELE_NUDGE: 'Tele Nudge',
};

export const masterConfig: any[] = [
  {
    id: 'firstName',
    label: 'First Name',
    value: '',
    path: 'first_name',
    editable: true,
    component: 'input-container',
    validate: 'alpha',
    type: 'TEXT',
    inputRule: 'alpha',
  },
  {
    id: 'lastName',
    label: 'Last Name',
    value: '',
    path: 'last_name',
    editable: true,
    component: 'input-container',
    validate: 'alpha',
    type: 'TEXT',
    inputRule: 'alpha',
  },
  {
    id: 'salary',
    label: 'Salary',
    value: '',
    path: 'salary',
    editable: true,
    component: 'input-container',
    validate: 'numeric',
    type: 'TEXT',
    inputRule: 'digits',
  },
  {
    id: 'monthSalaryCredit',
    label: 'Month of Salary Credit (Upto 3 months)',
    value: '',
    editable: true,
    path: 'month_of_salary_credit',
    component: 'input-container',
    validate: '',
    type: 'TEXT',
    inputRule: 'alphaNumericSpace',
  },
  {
    id: 'customerBankName',
    label: 'Customer Bank Name',
    value: '',
    editable: true,
    path: 'customer_bank_name',
    component: 'input-container',
    validate: '',
    type: 'TEXT',
    inputRule: 'alphaNumericSpace',
  },
  {
    id: 'customerBankAccountNumber',
    label: 'Customer Bank Account number',
    value: '',
    editable: true,
    path: 'customer_bank_account_number',
    component: 'input-container',
    validate: '',
    type: 'TEXT',
    inputRule: 'digits',
  },
  {
    id: 'pan',
    label: 'PAN',
    value: '',
    editable: true,
    path: 'pan',
    component: 'input-container',
    validate: 'pancard',
    type: 'TEXT',
    inputRule: 'alphaNumeric',
  },
  {
    id: 'uan',
    label: 'UAN',
    value: '',
    editable: true,
    path: 'uan',
    component: 'input-container',
    validate: 'digits: 12',
    type: 'TEXT',
    inputRule: 'isAlphaNumericSpace',
  },
  {
    id: 'companyName',
    label: 'Company Name',
    value: '',
    editable: true,
    path: 'company_name',
    component: 'input-container',
    validate: '',
    type: 'TEXT',
    inputRule: 'isAlphaNumericSpace',
  },
  {
    id: 'doj',
    label: 'Date of Joining (EPFO) (YYYY-MM-DD)',
    value: '',
    editable: true,
    path: 'doj',
    component: 'input-container',
    validate: '',
    type: 'TEXT',
    inputRule: 'isAlphaNumericSpace',
  },
  {
    id: 'pdfModified',
    label: 'PDF modified',
    value: '',
    editable: true,
    path: 'pdf_modified',
    component: 'select-container',
    selectOptions: {
      YES: 'Yes',
      NO: 'No',
      NOT_AVAILABLE: 'Not available',
    },
    validate: '',
    type: 'TEXT',
  },
  {
    id: 'badPdfMaker',
    label: 'Bad PDF Maker',
    value: '',
    editable: true,
    path: 'bad_pdf_maker',
    component: 'select-container',
    selectOptions: {
      YES: 'Yes',
      NO: 'No',
      NOT_AVAILABLE: 'Not available',
    },
    validate: '',
    type: 'TEXT',
  },
  {
    id: 'anyOtherBadTriggers',
    label: 'Any other bad triggers',
    value: '',
    editable: true,
    path: 'any_other_bad_triggers',
    component: 'select-container',
    selectOptions: {
      YES: 'Yes',
      NO: 'No',
      NOT_AVAILABLE: 'Not available',
    },
    validate: '',
    type: 'TEXT',
  },
  {
    id: 'badTriggerReason',
    label: 'Bad Trigger Reason',
    value: '',
    editable: true,
    path: 'bad_triggers_reason',
    component: 'input-container',
    validate: '',
    type: 'TEXT',
  },
];

export const relevantFields: any = {
  bank_statement: [
    'firstName',
    'lastName',
    'salary',
    'monthSalaryCredit',
    'customerBankName',
    'customerBankAccountNumber',
    'pan',
    'companyName',
    'doj',
    'pdfModified',
    'badPdfMaker',
    'anyOtherBadTriggers',
    'badTriggerReason',
  ],
  salary_statement: [
    'firstName',
    'lastName',
    'salary',
    'monthSalaryCredit',
    'customerBankName',
    'customerBankAccountNumber',
    'pan',
    'uan',
    'companyName',
    'doj',
    'pdfModified',
    'badPdfMaker',
    'anyOtherBadTriggers',
    'badTriggerReason',
  ],
  itr_form_16: [
    'firstName',
    'lastName',
    'salary',
    'monthSalaryCredit',
    'customerBankName',
    'customerBankAccountNumber',
    'pan',
    'companyName',
    'doj',
    'pdfModified',
    'badPdfMaker',
    'anyOtherBadTriggers',
    'badTriggerReason',
  ],
};

export const metaDataConfig: any = {
  bank_statement: masterConfig.filter((item: any) =>
    relevantFields.bank_statement.includes(item.id)
  ),
  salary_statement: masterConfig.filter((item: any) =>
    relevantFields.salary_statement.includes(item.id)
  ),
  itr_form_16: masterConfig.filter((item: any) =>
    relevantFields.itr_form_16.includes(item.id)
  ),
};

export const documentMetaDataConfig = metaDataConfig;
